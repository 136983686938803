import React from "react";
import { Trans } from "react-i18next";
import { Icon, Icons } from "../Icon/Icon";
import "./SectionPromo.css";

const SectionPromo = () => {
    return (
        <section id="promo" className="promo">
            <div className="slogan wow fadeInDown">
                <Trans i18nKey="promo.title">
                    <p>Next level of</p>
                    <p><span>Next</span> Real estate sales</p>
                </Trans>
            </div>
            <div className="container">
                <div className="content">
                    <p className="description wow fadeInDown">
                        <Trans i18nKey="promo.description">
                            Complete marketing solution for targeting and acquiring customers
                        </Trans>
                    </p>
                    <div className="buttons wow fadeInDown">
                        <button className="white" onClick={() =>
                            window.scrollTo(0, document.getElementById('contact')!.offsetTop - 106)
                        }>
                            <Trans i18nKey="promo.hire">Hire us</Trans>
                        </button>
                        {/*<button className="transparent">*/}
                        {/*    <a href="/presentation.pdf" target="_blank" rel="nofollow noreferrer">View presentation</a>*/}
                        {/*</button>*/}
                        {/*<button class="transparent">Try demo</button>*/}
                    </div>
                    <div className="items">
                        <div className="item wow fadeInLeft">
                            <h4>
                                <Icon icon={Icons.PromoCheck} width={16} height={16}/>
                                <span>
                                    <Trans i18nKey="promo.items.0.title">Entire sales procedure</Trans>
                                </span>
                            </h4>
                            <p>
                                <Trans i18nKey="promo.items.0.description">
                                    50k.io assist you at every stage of your business, from planning the project to
                                    completing the sale
                                </Trans>
                            </p>
                        </div>
                        <div className="item wow fadeInRight">
                            <h4>
                                <Icon icon={Icons.PromoTime} width={16} height={16}/>
                                <span>
                                    <Trans i18nKey="promo.items.1.title">Interactive 3D model</Trans>
                                </span>
                            </h4>
                            <p>
                                <Trans i18nKey="promo.items.1.description">
                                    Light version: path traced video scenarios, minimum loading time and high page
                                    performance
                                </Trans>
                            </p>
                        </div>
                        <div className="item wow fadeInLeft">
                            <h4>
                                <Icon icon={Icons.PromoDevices} width={16} height={16}/>
                                <span>
                                    <Trans i18nKey="promo.items.2.title">3D journey on all devices</Trans>
                                </span>
                            </h4>
                            <p>
                                <Trans i18nKey="promo.items.2.description">
                                    Full version: own streaming platform for exploring a 3D virtual tours
                                </Trans>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="image wow fadeInRight">
                    <img src="/img/desktop.png" alt="Desktop"/>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/4U4v5uaCWmQ?si=KTx6uIrrp2gi1vzQ&amp;controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=4U4v5uaCWmQ"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
                <div className="mobile buttons wow fadeInDown">
                    <button className="white" onClick={() =>
                        window.scrollTo(0, document.getElementById('contact')!.offsetTop - 106)
                    }>
                        <Trans i18nKey="promo.hire">Hire us</Trans>
                    </button>
                    {/*<button class="transparent">Try demo</button>*/}
                </div>
                <p className="mobile description wow fadeInDown">
                    <Trans i18nKey="promo.description">
                        Complete marketing solution for targeting and acquiring customers
                    </Trans>
                </p>
            </div>
        </section>
    );
}

export default SectionPromo;
